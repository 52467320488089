import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {MdKeyboardArrowRight} from 'react-icons/md'

const query = graphql`
{
    allStrapiJobs(sort: {fields: strapiId, order: DESC}) {
      nodes {
        date {
          start
          end
        }
        strapiId
        position
        company
        description {
          id
          name
        }
      }
    }
  }
`

export default function Jobs() {
    const data = useStaticQuery(query);
    const [value, setValue] = useState(0);
    let {
        allStrapiJobs: { nodes: jobs }
    } = data;
    const { position, date, description, company } = jobs[value];
    return (
        <div className="jobs">
            <h4 className="text-primary">Experience</h4>
            <div className="jobs-container">
                <div className="button-container">
                    {jobs.map((job, index) => (
                        <button onClick={() => setValue(index)} className={index === value ? 'active' : ''} key={job.strapiId}>
                            {job.company}
                        </button>
                    ))}
                </div>
                <article className="job-info">
                    <h5>
                        {position}
                    </h5>
                    <p className="job-company">{company}</p>
                    <p className="job-date">
                        {

                            (
                                (new Date(date.start).getFullYear())
                                + ' - ' +
                                (date.end === '2010-07-06' ? 'present' : new Date(date.end).getFullYear()))
                        }
                    </p>
                    {
                        description.map(desc => (
                            <div key={desc.id} className="job-dec">
                                <MdKeyboardArrowRight />
                                <p>{desc.name}</p>
                            </div>
                        ))
                    }
                </article>
            </div>
        </div>
    )
}
