import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Jobs from '../components/Jobs';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import getIcon from '../constants/icons'
import HashLink from '../components/HashLink';
export default ({data}) => {
    const { allStrapiReadme: {nodes: [{ stack, description }] } } = data;
    return (
        <Layout>
            <SEO title="About" description="learn more about me"></SEO>
            <div className="section-container top" id="about">
                <div className="svg-background"></div>
                <div className="about-section">
                    <div className="image">
                        <img src="https://avatars0.githubusercontent.com/u/23521035?v=4" width="350px" alt="author" />
                    </div>
                    <div className="about-text">
                        <div className="markdown">
                            <ReactMarkdown source={description}/>
                        </div>
                        <h4 className="text-primary">Skills</h4>
                        <div className="skill-container">
                            {
                                stack.map(({id, icon, name})=>{
                                    const Icon = getIcon(icon);
                                    return(
                                        <div key={id} className="tech-icon">
                                            <Icon/>
                                            <span className="text">{name}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="btn-block">
                            <HashLink to="/#portfolio" className="btn btn-border">View Portfolio</HashLink>
                            <Link to="" className="btn btn-full">Download Resume</Link>
                        </div>
                    </div>
                </div>
                <Jobs/>
            </div>
        </Layout>
    )
}

export const query = graphql`
  {
    allStrapiReadme {
      nodes {
        stack {
          icon
          id
          name
        }
        description
      }
    }
  }
`
